import React, { useRef, useEffect, useState } from "react";
import s from "./about.module.css";
import { TweenLite, TimelineMax, Power2 } from "gsap";

export const AboutSection = ({ setAboutSectionScrollTrigger }) => {
  const imgElement = useRef(null);
  const animationTrigger = useRef(null);
  const scrollTo = useRef(null);
  const userImageElement = useRef(null);
  const userAbountElement = useRef(null);
  const [trigger, setTrigger] = useState(false);
  const [animationCount, setAnimationCount] = useState(0);

  useEffect(() => {
    setAboutSectionScrollTrigger(scrollTo.current);
    const isInViewport = () => {
      const rect = animationTrigger.current.getBoundingClientRect();
      setTrigger(
        rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
      );
    };
    window.addEventListener("scroll", isInViewport);
    return () => {
      window.removeEventListener("scroll", isInViewport);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (trigger && animationCount === 0) {
      let tl = new TimelineMax();
      setAnimationCount(1);
      TweenLite.set(userAbountElement.current, { visibility: "visible" });
      TweenLite.set(userImageElement.current, { visibility: "visible" });
      if (trigger) {
        tl.from(
          userAbountElement.current,
          1.5,
          { y: 1280, opacity: 0, visibility: "visible" },
          Power2.easeIn
        )
          .from(
            userImageElement.current,
            1.5,
            { y: -1280, opacity: 0, visibility: "visible" },
            Power2.easeIn,
            2
          )
          .staggerFrom(
            [].slice.call(userAbountElement.current.children),
            1,
            {
              opacity: 0,
              y: 20,
            },
            0.3
          );
      }
    }
    // eslint-disable-next-line
  }, [trigger]);

  useEffect(() => {
    const parallax = () => {
      let value = window.scrollY;
      imgElement.current.style.top = value * 0.5 + "px";
    };
    window.addEventListener("scroll", parallax);
    return () => {
      window.removeEventListener("scroll", parallax);
    };
  }, []);
  return (
    <div className={s.container} ref={scrollTo}>
      <img
        ref={imgElement}
        src="./images/test.jpg"
        className={s.background}
        alt="background_image"
      />
      <div className={s.animationTrigger} ref={animationTrigger}></div>
      <div className={s.contentContainer}>
        <div className={s.imageContainer} ref={userImageElement}>
          <img src="./images/me.jpg" className={s.myImage} alt="my_image" />
        </div>
        <div className={s.aboutTextContainer} ref={userAbountElement}>
          <h2 className={s.aboutTitle}> Who Am I ?</h2>
          <p>
            A tech enthusiast and web developer who doesn't believe in taking
            shortcuts.
          </p>
          <p>
            I love to develop exciting web solutions that enables rich
            producitvity.
          </p>
          <p>
            Also a passionate competetive Gamer and seasonal nature
            photographer.
          </p>
        </div>
      </div>
    </div>
  );
};
