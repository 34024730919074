import React, { useRef, useState } from "react";
import { HeadSection } from "./views/HeadSection";
import { AboutSection } from "./views/AboutSection";
import { SkillSection } from "./views/SkillSection";
import { ProjectsSection } from "./views/ProjectsSection";
import { Divider } from "./components/Divider";
import { FooterSection } from "./views/FooterSection";

const App = () => {
  const navElement = useRef(null);
  const [aboutSectionScrollTrigger, setAboutSectionScrollTrigger] = useState(
    null
  );
  const [skillSectionScrollTrigger, setSkillSectionScrollTrigger] = useState(
    null
  );
  const [
    contactSectionScrollTrigger,
    setContactSectionScrollTrigger,
  ] = useState(null);
  const [
    projectSectionScrollTrigger,
    setProjectSectionScrollTrigger,
  ] = useState(null);

  return (
    <>
      <div className="app">
        <HeadSection
          nav={navElement}
          aboutSectionScrollTrigger={aboutSectionScrollTrigger}
          skillSectionScrollTrigger={skillSectionScrollTrigger}
          contactSectionScrollTrigger={contactSectionScrollTrigger}
          projectSectionScrollTrigger={projectSectionScrollTrigger}
        />
        <Divider />

        <AboutSection
          setAboutSectionScrollTrigger={setAboutSectionScrollTrigger}
        />
        <Divider />

        <SkillSection
          setSkillSectionScrollTrigger={setSkillSectionScrollTrigger}
        />
        <Divider />

        <ProjectsSection
          setProjectSectionScrollTrigger={setProjectSectionScrollTrigger}
        />
        <Divider />

        <FooterSection
          setContactSectionScrollTrigger={setContactSectionScrollTrigger}
        />
      </div>
    </>
  );
};

export default App;
