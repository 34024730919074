import React, { useState } from "react";
import s from "./card.module.css";

export const Card = ({ data, confidential = false }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const changeImage = (flag) => {
    if (flag) {
      setCurrentImage((prev) => prev + 1);
    } else {
      setCurrentImage((prev) => prev - 1);
    }
  };

  return (
    <div className={s.container}>
      <img
        src={data.images[currentImage]}
        className={s.image}
        alt="project_image"
      ></img>
      <div className={s.imageText}>{data.name}</div>
      {!confidential ? (
        <div className={s.controlContainer}>
          <span
            onClick={() => changeImage(false)}
            className={`fa fa-chevron-left ${s.control} ${
              !currentImage && s.controlHidden
            }`}
            aria-hidden="true"
          />

          <span
            onClick={() => changeImage(true)}
            className={`fa fa-chevron-right ${s.control} ${
              currentImage === data.images.length - 1 && s.controlHidden
            }`}
            aria-hidden="true"
          />
        </div>
      ) : (
        <div className={s.confidential}>Confidential</div>
      )}
    </div>
  );
};
