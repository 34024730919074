import React, { useState } from "react";
import styles from "./navbar.module.css";
import { TweenLite } from "gsap";
import gsap, { Power2 } from "gsap";
import { ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

export const Navbar = ({
  nav,
  aboutSectionScrollTrigger,
  skillSectionScrollTrigger,
  contactSectionScrollTrigger,
  projectSectionScrollTrigger,
}) => {
  const scrollToSection = (sectionElement) => {
    setToggleSidebar(false);
    TweenLite.to(window, 1, { scrollTo: sectionElement, ease: Power2.easeOut });
  };
  const [toggleSidebar, setToggleSidebar] = useState(false);

  return (
    <div className={styles["navbar"]} ref={nav}>
      <div className={styles["brand"]}>
        <img
          src="./images/avatar_white.jpg"
          className={styles["logo"]}
          alt="logo"
        ></img>
        {/* <div className={styles["brand-name"]}>Me</div> */}
      </div>
      <div className={styles["nav-items"]}>
        <div onClick={() => scrollToSection(aboutSectionScrollTrigger)}>
          About
        </div>
        <div onClick={() => scrollToSection(skillSectionScrollTrigger)}>
          Skills
        </div>
        <div onClick={() => scrollToSection(projectSectionScrollTrigger)}>
          Projects
        </div>
        <div onClick={() => scrollToSection(contactSectionScrollTrigger)}>
          Contact
        </div>
      </div>
      <div
        className={styles.sidebarToggle}
        onClick={() => setToggleSidebar(!toggleSidebar)}
      >
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
      <div
        className={`${styles.sidebar} ${
          toggleSidebar && styles["sidebar-on"]
        } `}
      >
        <div
          className={styles.sidebarItem}
          onClick={() => scrollToSection(aboutSectionScrollTrigger)}
        >
          About
        </div>
        <div
          className={styles.sidebarItem}
          onClick={() => scrollToSection(skillSectionScrollTrigger)}
        >
          Skills
        </div>
        <div
          className={styles.sidebarItem}
          onClick={() => scrollToSection(projectSectionScrollTrigger)}
        >
          Projects
        </div>
        <div
          className={styles.sidebarItem}
          onClick={() => scrollToSection(contactSectionScrollTrigger)}
        >
          Contact
        </div>
      </div>
      {/* <div className={styles.sideBar}></div> */}
    </div>
  );
};
