import React, { useEffect, useRef, useState } from "react";
import s from "./skill.module.css";
import { TimelineLite, TweenLite, Power2 } from "gsap";
import { SkillSet } from "../../components/SkillSet";
export const SkillSection = ({ setSkillSectionScrollTrigger }) => {
  const containerElement = useRef(null);
  const animationTrigger = useRef(null);
  const skillContainerElement = useRef(null);
  const [trigger, setTrigger] = useState(false);
  const [animationCount, setAnimationCount] = useState(0);

  useEffect(() => {
    setSkillSectionScrollTrigger(containerElement.current);
    const isInViewport = () => {
      const rect = animationTrigger.current.getBoundingClientRect();
      setTrigger(
        rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
      );
    };
    window.addEventListener("scroll", isInViewport);
    return () => {
      window.removeEventListener("scroll", isInViewport);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (trigger && animationCount === 0) {
      const tl = new TimelineLite();
      setAnimationCount(1);

      const container = containerElement.current;
      const skillsHeading = container.children[0].children[0];
      const parahgraphArray = [
        container.children[0].children[1].children[0],
        container.children[0].children[1].children[2],
        container.children[0].children[1].children[3],
        container.children[0].children[1].children[5],
      ];
      TweenLite.set(container, { visibility: "visible" });

      tl.from(container, 1, { x: 1280, opacity: 0 })
        .from(
          skillsHeading,
          1.3,
          {
            y: 20,
            opacity: 0,
            ease: Power2.easeOut,
          },

          0.6
        )
        .from(
          skillContainerElement.current,
          1,
          {
            y: 1280,
            opacity: 0,
            ease: Power2.easeOut,
          },
          0.7
        )
        .staggerFrom(
          parahgraphArray,
          1,
          { y: -20, opacity: 0, ease: Power2.easeOut },
          0.3,
          0.3
        );
    }
    // eslint-disable-next-line
  }, [trigger]);

  return (
    <div className={s.overflowHidden}>
      <div className={s.container} ref={containerElement}>
        <div className={s.banner}>
          <h1>My Skills</h1>
          <div>
            <p>
              I am working as a Full stack developer concentrated on building
              robust web APIs using various technological stacks
            </p>
            <br />
            <p>
              Love building interactive and dynamic single page web applications
              with React js being my weapon of choice
            </p>
            <p>
              Also experienced with Continous integration and Deployment method
              of software development cycle and hands on experience with Cloud
              technologies including AWS, Azure, Docker and Kubernetes
            </p>
            <br />
            <p>
              Have worked with Agile software development process and
              experienced in leading team of developers
            </p>
          </div>
        </div>
        <SkillSet skillContainerElement={skillContainerElement} />
      </div>
      <div className={s.animationTrigger} ref={animationTrigger}></div>
    </div>
  );
};
