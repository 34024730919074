import React, { useEffect, useRef } from "react";
import s from "./footer.module.css";

export const FooterSection = ({ setContactSectionScrollTrigger }) => {
  const containerElement = useRef(null);

  const triggerMail = () => {
    window.location.href = `mailto:robinthehood13@gmail.com`;
  };

  const redirectTo = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    setContactSectionScrollTrigger(containerElement.current);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={s.container} ref={containerElement}>
      <div className={s.contactBanner}>
        <div className={s.getStarted}>Start a project</div>
        <div className={s.hireMe}>
          <div>Interested in hiring or collaboration for a project ?</div>
          <div>Let's queue up a chat.</div>
        </div>
        <button className={s.connectButton} onClick={triggerMail}>
          Let's Connect
        </button>
      </div>
      <div className={s.quote}>
        “In order to be irreplaceable, one must always be different”
        <div>– Coco Chanel</div>
      </div>
      <div className={s.socialIconContainer}>
        <Social
          icon="fa fa-twitter"
          redirectTo={redirectTo}
          link="https://twitter.com/robinat13"
        />
        <Social
          icon="fa fa-github"
          redirectTo={redirectTo}
          link="https://github.com/robinat13"
        />
        <Social
          icon="fa fa-linkedin"
          redirectTo={redirectTo}
          link="https://www.linkedin.com/in/robin-chauhan-515354152/"
        />
        <Social
          icon="fa fa-facebook"
          redirectTo={redirectTo}
          link="https://www.facebook.com/robin.chauhan.1806"
        />
        <Social
          icon="fa fa-youtube"
          redirectTo={redirectTo}
          link="https://www.youtube.com/channel/UCcX0LP-By8WsHAhkBJj03tA"
        />
      </div>
      <div className={s.crafted}>Handcrafted with love using React js</div>
      <div className={s.queries}>
        <span>For any queries contact me at </span>
        <span className={s.mail}>robinthehood13@gmail.com</span>
      </div>
    </div>
  );
};

const Social = ({ icon, redirectTo, link }) => (
  <div className={`${icon} ${s.socialIcon}`} onClick={() => redirectTo(link)} />
);
