import React, { useEffect, useRef } from "react";
import styles from "./floatingcloud.module.css";
import { TimelineLite } from "gsap";

export const FloatingCloud = ({
  top,
  left,
  right,
  width,
  height,
  opacity,
  animation,
}) => {
  const containerStyle = {
    top: top && `${top}%`,
    left: left && `${left}%`,
    right: right && `${right}%`,
  };

  const imageStyles = {
    opacity: opacity ? opacity : 1,
    width: width ? width : "150px",
    height: height ? height : "80px",
  };

  const cloudElement = useRef(null);
  const tl = new TimelineLite();

  useEffect(() => {
    tl.to(cloudElement.current, 0, {
      css: { visibility: "visible" },
    }).to(cloudElement.current, animation === "left" ? 60 : 40, {
      x: animation === "left" ? -200 : 200,
      y: animation === "left" ? -50 : 80,
      repeat: -1,
      yoyo: true,
    });
  }, [tl, animation]);

  return (
    <div
      className={styles["cloud-container"]}
      style={containerStyle}
      ref={cloudElement}
    >
      <img
        src="./images/crayon-cloud.png"
        alt="floating_cloud"
        className={styles["floating-cloud"]}
        style={imageStyles}
      />
    </div>
  );
};
