import React, { useRef, useEffect } from "react";
import s from "./projects.module.css";
import { Card } from "../../components/Card";

export const ProjectsSection = ({ setProjectSectionScrollTrigger }) => {
  const containerElement = useRef(null);

  useEffect(() => {
    setProjectSectionScrollTrigger(containerElement.current);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={s.container} ref={containerElement}>
      <div className={s.title}>My Recent Work</div>
      <div className={s.cardContainer}>
        <Card
          data={{
            name: "Api Market Place",
            images: [
              "./images/projects/api_market_1.png",
              "./images/projects/api_market_2.png",
              "./images/projects/api_market_3.png",
              "./images/projects/api_market_4.png",
              "./images/projects/api_market_5.png",
            ],
          }}
        />
        <Card
          data={{
            name: "Business Command Control",
            images: [
              "./images/projects/bcc_1.png",
              "./images/projects/bcc_2.png",
              "./images/projects/bcc_3.png",
              "./images/projects/bcc_4.png",
              "./images/projects/bcc_5.png",
            ],
          }}
        />
        <Card
          data={{
            name: "Agile Core",
            images: [
              "./images/projects/agile_core.png",
              "./images/projects/agile_core_1.png",
              "./images/projects/agile_core_2.png",
              "./images/projects/agile_core_3.png",
            ],
          }}
        />
        <Card
          data={{
            name: "Carbon Dashboard",
            images: ["./images/mountain.png"],
          }}
          confidential
        />
      </div>
    </div>
  );
};
