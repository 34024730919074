import React, { useRef, useEffect } from "react";
import styles from "./me.module.css";

export const Me = () => {
  const roleElement = useRef(null);
  const cursorElement = useRef(null);

  const textArray = [
    "Back End Development",
    "Front End Solutions",
    "Full Stack Web Development 😄",
  ];

  const typingDelay = 150;
  const erasingDelay = 75;
  const newTextDelay = 1500;

  let textArrayIndex = 0;
  let charIndex = 0;

  useEffect(() => {
    if (textArray.length) setTimeout(type, newTextDelay + 250);
    // eslint-disable-next-line
  }, []);

  const type = () => {
    if (charIndex < textArray[textArrayIndex].length) {
      if (!cursorElement.current.classList.contains("typing"))
        cursorElement.current.classList.add("typing");
      roleElement.current.textContent += textArray[textArrayIndex].charAt(
        charIndex
      );
      charIndex++;
      setTimeout(type, typingDelay);
    } else {
      cursorElement.current.classList.remove("typing");
      setTimeout(erase, newTextDelay);
    }
  };

  const erase = () => {
    if (charIndex > 0) {
      if (!cursorElement.current.classList.contains("typing"))
        cursorElement.current.classList.add("typing");
      roleElement.current.textContent = textArray[textArrayIndex].substring(
        0,
        charIndex - 1
      );
      charIndex--;
      setTimeout(erase, erasingDelay);
    } else {
      cursorElement.current.classList.remove("typing");
      textArrayIndex++;
      if (textArrayIndex >= textArray.length) textArrayIndex = 0;
      setTimeout(type, typingDelay + 1100);
    }
  };

  return (
    <div className={styles["me-container"]}>
      <div className={styles["container"]}>
        <div className={styles["hi"]}>Hi,</div>
        <div className={styles["name"]}>My name is Robin,</div>
        <div className={styles["name"]}>I do</div>
        <div className={styles["role-container"]}>
          <p>
            <span ref={roleElement} className={styles["typed-text"]}></span>
            <span ref={cursorElement} className={styles["cursor"]}>
              &nbsp;
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
