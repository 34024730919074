import React, { useRef, useEffect, useState } from "react";
import styles from "./headsection.module.css";
import { FloatingCloud } from "../../components/FloatingClouds";
import { Navbar } from "../../components/Navbar";
import { Me } from "../../components/Me";
import { TweenLite, Power2 } from "gsap";

export const HeadSection = ({
  nav,
  aboutSectionScrollTrigger,
  skillSectionScrollTrigger,
  contactSectionScrollTrigger,
  projectSectionScrollTrigger,
}) => {
  const imgElement = useRef(null);
  const headElement = useRef(null);

  const [headElementInView, setHeadElementInView] = useState(false);
  const [contactElementInView, setContactElementInView] = useState(false);

  const changeCurrentElementInView = (flag) => {
    let top = window.pageYOffset || document.documentElement.scrollTop;
    if (flag) {
      scrollToSection(top + 1080);
    } else {
      scrollToSection(top - 1080);
    }
  };

  const scrollToSection = (y) => {
    TweenLite.to(window, 1, {
      scrollTo: { y: y },
      ease: Power2.easeOut,
    });
  };

  useEffect(() => {
    const parallax = () => {
      let value = window.scrollY;
      imgElement.current.style.top = value * 0.5 + 128 + "px";
    };
    window.addEventListener("scroll", parallax);
  }, []);

  useEffect(() => {
    const isInViewport = () => {
      const rectHeadElement = headElement.current.getBoundingClientRect();
      setHeadElementInView(
        rectHeadElement.top >= 0 &&
          rectHeadElement.left >= 0 &&
          rectHeadElement.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rectHeadElement.right <=
            (window.innerWidth || document.documentElement.clientWidth)
      );

      if (contactSectionScrollTrigger) {
        const rectContactElement = contactSectionScrollTrigger.getBoundingClientRect();
        setContactElementInView(
          rectContactElement.top >= 0 &&
            rectContactElement.left >= 0 &&
            rectContactElement.bottom <=
              (window.innerHeight || document.documentElement.clientHeight) &&
            rectContactElement.right <=
              (window.innerWidth || document.documentElement.clientWidth)
        );
      }
    };
    window.addEventListener("scroll", isInViewport);
    return () => {
      window.removeEventListener("scroll", isInViewport);
    };
    // eslint-disable-next-line
  }, [contactSectionScrollTrigger]);

  return (
    <div className={styles["head"]} ref={headElement}>
      <div className={styles.siteControlButtonContainer}>
        <div
          className={`fa fa-arrow-up ${styles.siteControlButton} ${
            headElementInView && styles.siteControlHide
          }`}
          onClick={() => changeCurrentElementInView(false)}
        ></div>
        <div
          className={`fa fa-arrow-down ${styles.siteControlButton} ${
            contactElementInView && styles.siteControlHide
          }`}
          onClick={() => changeCurrentElementInView(true)}
        ></div>
      </div>
      <div className={styles["head-background-container"]}>
        <img
          ref={imgElement}
          src="./images/mountain.png"
          alt="mountain_image"
          className={styles["head-background-image"]}
        />
      </div>
      <FloatingCloud
        top="20"
        left="10"
        opacity="0.3"
        animation="right"
        width="180px"
        height="100px"
      />
      <FloatingCloud right="10" top="10" opacity="0.4" animation="left" />
      {/* NAVBAR */}
      <Navbar
        nav={nav}
        aboutSectionScrollTrigger={aboutSectionScrollTrigger}
        skillSectionScrollTrigger={skillSectionScrollTrigger}
        contactSectionScrollTrigger={contactSectionScrollTrigger}
        projectSectionScrollTrigger={projectSectionScrollTrigger}
      />

      {/* ME */}
      <Me />
    </div>
  );
};
