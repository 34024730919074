import React from "react";
import s from "./skill.module.css";

export const SkillSet = ({ skillContainerElement }) => {
  return (
    <div className={s.container} ref={skillContainerElement}>
      <Skill
        data={{
          icon: "fa fa-code",
          title: "Back-End",
          description:
            "I value clean and robust code, battle tested design patterns and security of the Vikings",
          subheading: "Things I enjoy developing",
          loveTo:
            "Web Apis, REST Services, Micro-services, JWT Secured, Event driven microservices",
          languages: "JAVA, Node js, Express Js, Mongo Db, SQL, Spring Boot",
          tools: [
            "Spring Tools Suite",
            "Visual Studio Code",
            "Maven",
            "Lombok",
          ],
        }}
      />
      <Skill
        data={{
          icon: "fa fa-tachometer",
          title: "Front-end",
          description:
            "I like to code things valuing component based design patterns, and enjoy bringing ideas to life in the browser.",
          subheading: "Things I enjoy developing",
          loveTo:
            "Single Page web applications, Responsive design, Component driven architecture",
          languages: "HTML, CSS, Javascript, Typescript, Sass",
          tools: [
            "Visual Studio Code",
            "React js",
            "Bootstrap",
            "GSAP",
            "Redux",
            "Axios",
          ],
        }}
      />
      <Skill
        data={{
          icon: "fa fa-line-chart",
          title: "Extras",
          description:
            "Things I enjoy working with that makes quality of life better",
          subheading: "Things I enjoy developing",
          loveTo:
            "Continous Integration using Git, Continous Deployment, Containerization, Docker, Kubernetes, Cloud",
          languages: "DOS, Scripting, Git",
          tools: ["Docker", "Kubernetes", "Linux", "Terminal", "Git bash"],
        }}
      />
    </div>
  );
};

const Skill = ({ data }) => (
  <div className={s.skillContainer}>
    <div className={`${data.icon} ${s.icon}`} />
    <h1 className={s.heading}>{data.title}</h1>
    <div className={s.description}>{data.description}</div>
    <div className={s.subheading}>{data.subheading}</div>
    <div className={s.loveTo}>{data.loveTo}</div>
    <div className={s.subheading}>Languages I speak</div>
    <div className={s.loveTo}>{data.languages}</div>
    <div className={s.subheading}>Tools and Libraries</div>
    <div className={s.tools}>
      {data.tools.map((tool) => (
        <div key={tool}>{tool}</div>
      ))}
    </div>
  </div>
);
